import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/books": [~3],
		"/books/new": [~6],
		"/books/[id]": [~4],
		"/books/[id]/edit": [~5],
		"/edits": [~7],
		"/edits/item/[id]": [~9],
		"/edits/[id]": [~8],
		"/genres": [~10],
		"/genres/[id]": [~11],
		"/importers/audnexus": [~12],
		"/people": [~13],
		"/people/new": [~16],
		"/people/[id]": [~14],
		"/people/[id]/edit": [~15],
		"/privacy": [17],
		"/publishers": [~18],
		"/publishers/[id]": [~19],
		"/releases": [~20],
		"/releases/new": [~23],
		"/releases/[id]": [~21],
		"/releases/[id]/edit": [~22],
		"/search/people": [~24],
		"/search/releases": [~25],
		"/search/series": [~26],
		"/series": [~27],
		"/series/new": [~30],
		"/series/[id]": [~28],
		"/series/[id]/edit": [~29],
		"/tags/[id]": [~31],
		"/tos": [32],
		"/users/login": [~35],
		"/users/logout": [36],
		"/users/signup": [~37],
		"/users/[username]": [~33],
		"/users/[username]/edit": [~34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';